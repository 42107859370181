<template>
  <multi-step-dialog title="Select a Map" :step="step" ref="dialog" :can-confirm="!!templateId" @input="confirm">
    <v-stepper-items>
      <v-stepper-content :step="1" class="pa-0">
        <div v-if="!mapTemplates" class="text-center">
          <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
        </div>
        <v-container v-if="mapTemplates">
          <p>Select a map for details and to apply.</p>
          <v-row>
            <v-col v-for="(item, idx) in mapTemplates" :key="idx" cols="6" md="4" lg="3">
              <v-card @click="templateId = item.id;step=2;" :class="{ active: value == item.id}">
                <v-img
                  v-if="item.img"
                  :src="item.img"
                  class="white--text align-end relative"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-card-title>{{item.name}} &raquo;</v-card-title>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

      </v-stepper-content>
      <v-stepper-content :step="2" class="pa-0">
        <v-container v-if="template">
          <v-row>
            <v-col>
              <h4>{{ template.name }}</h4>
              <p>{{ template.description }}</p>

              <p>
                <v-btn :to="{ name: 'challengeJourneyView', params: {id: template.id }}" target="_blank" outlined class="">View Details</v-btn>
              </p>

              <h4>Course Map</h4>
              <RaceResultsMap ref="resultMap" height="300px" :event="{ id: this.template.id, name: this.template.name }" :race="template" :badges="template.badges" :map-options="{dragging: true, scrollWheelZoom: false}" />

            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </multi-step-dialog>
</template>

<script>
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import assetsService from "@/services/assetsService";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import MultiStepDialog from "@/components/generic/MultiStepDialog.vue";

export default {
  name: "SelectMapDialog",
  components: {
    MultiStepDialog,
    RaceResultsMap,
  },
  props: {
    value: String,
  },
  data() {
    return {
      siteData: siteData,
      mapTemplates: null,
      templateId: null,
      step: 1,
    };
  },
  async mounted() {
  },
  methods: {

    async open() {
      this.step = 1;
      this.$refs.dialog.open();
      this.mapTemplates = this.mapTemplates || (await assetsService.getMapTemplates()).data.data;
    },

    async confirm() {
      this.$emit('input', this.templateId);
    }

  },

  computed: {
    template() {
      if (!this.templateId) {
        return null;
      }
      return this.mapTemplates.find(x => x.id == this.templateId);
    }
  },

};
</script>
<style lang="scss">
.v-card.active { outline: solid 5px var(--v-accent-base);}
</style>

