<template>
  <div>

    <v-sheet v-if="decorator.type === 'TEAMS' " class="pa-0 mb-4" :elevation="0">
      <v-switch 
        v-if="!decorator.required"
        v-model="decorator.enabled"
        label="Add a team leaderboard"
        hide-details
        class="font-large"
        @change="$emit('change', decorator)"
        />
      <div v-if="decorator.enabled" class="decorator-fields">
        <h4>Team Options</h4>
        <p>Configure how you want teams to work for this event.</p>
        <ToggleButtonInput
          v-model="decorator.config.mode"
          label="How should teams be assigned?"
          :items="[{type:'SELF_MANAGED', text: 'Self-managed'}, {type:'RANDOM', text: 'Randomly'}, {type:'ADMIN_MANAGED', text: 'By admins'}]"
          />
        <v-select
          v-if="decorator.config.mode === 'RANDOM'"
          v-model="decorator.config.naming_algo"
          class="mt-4"
          label="How would you want to name the teams?"
          item-text="text"
          item-value="type"
          :items="siteData.team_naming_algos"
          />
        <v-text-field 
          v-if="decorator.config.mode === 'SELF_MANAGED' || decorator.config.mode === 'RANDOM'"
            v-model.number="decorator.config.max_members" 
            type="number"
            min="0" 
            step="1" 
            label="Max Team Size" 
            persistent-hint
            hint="Leave empty for an unrestricted team size."
            />
        <v-alert v-if="!!decorator.config.team_max_members && decorator.config.team_max_members < 2" type="info" dense>
          Please note that a value lower than 2 is not advised. This will not allow teams to be joined.
        </v-alert>

      </div>
    </v-sheet>

    <v-sheet v-if="decorator.type === 'DURATION' " class="pa-0 mb-4" :elevation="0">
      <v-switch 
        v-if="!decorator.required"
        v-model="decorator.enabled"
        hide-details
        class="font-large"
        @change="$emit('change', decorator)"
        >
        <template v-slot:label>
          Select duration for this event 
        </template>
      </v-switch>

      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        The duration of this event will be adjusted to <strong>{{ decorator.config.days }} days</strong> to be compatible with this template.
      </div>
    </v-sheet>

    <v-sheet v-if="decorator.type === 'STEP_CONVERSION' " class="pa-0 mb-4" :elevation="0">
      <v-switch 
        v-if="!decorator.required"
        v-model="decorator.enabled"
        hide-details
        class="font-large"
        @change="$emit('change', decorator)"
        >
        <template v-slot:label>
          Automatic Step Conversion 
          <v-chip outlined color="accent" small class="mx-2" title="This feature requires the Pro plan (or higher).">PRO</v-chip>
        </template>
      </v-switch>

      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        Non step-based activities are automatically converted to their step-equivalent based on the estimated effort. <a href="todo">Read More</a> about step conversions.
      </div>
    </v-sheet>

    <v-sheet v-if="decorator.type === 'MANUAL_ENTRY' " class="pa-0 mb-4" :elevation="0">
      <v-switch 
        v-model="decorator.enabled"
        :disabled="decorator.required"
        hide-details
        class="font-large"
        @change="$emit('change', decorator)"
        >
        <template v-slot:label>
          Allow Manual Entries
          <v-chip v-if="decorator.required" outlined color="accent" small class="mx-2" title="This feature is required for this configuration.">Required</v-chip>
          <simple-tooltip icon-class="ms-1" text="Users can manually enter entries."/>
        </template>
      </v-switch>

      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        This allows users to manually enter their activities.
      </div>
    </v-sheet>

    <v-sheet v-if="decorator.type === 'WEEK_DAYS' " class="pa-0 mb-4" :elevation="0">
      <v-switch 
        v-model="decorator.enabled"
        :disabled="decorator.required"
        hide-details
        class="font-large"
        @change="$emit('change', decorator)"
        >
        <template v-slot:label>
          Track only on week days
          <v-chip v-if="decorator.required" outlined color="accent" small class="mx-2" title="This feature is required for this configuration.">Required</v-chip>
        </template>
      </v-switch>

      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        When enabled only week days will count. Keep off to track also in the weekends. 
      </div>
    </v-sheet>

    <v-sheet v-if="decorator.type === 'DAILY_TARGET' " class="pa-0 mb-4" :elevation="0" color="transparent">
      <v-switch 
        v-if="!decorator.required"
        v-model="decorator.enabled"
        hide-details
        label="Enable daily target"
        @change="$emit('change', decorator)"
        />
      <div v-if="decorator.enabled" class="decorator-fields">
        <ToggleButtonInput
          v-if="decorator.config.type == null"
          v-model="decorator.config.type"
          label="Type of daily target"
          :items="[{type:'STEPS', text: 'Steps'}, {type:'TIME', text: 'Total Active Time'}, {type:'DISTANCE', text: 'Activity Distance'}]"
          />
        <DistanceTextArea
          v-if="decorator.config.type == 'STEPS'"
          v-model="decorator.config.steps" 
          :mode="$helpers.UnitType.NUMBER"
          :label="`Number of steps (per day)`" 
          hint="A typical goal is between 5,000 and 10,000 steps per day"
          />
        <DistanceTextArea
          v-if="decorator.config.type == 'TIME'"
          v-model="decorator.config.time_min" 
          :mode="$helpers.UnitType.NUMBER"
          :label="`Number of active minutes (per day)`" 
          hint="A typical goal is around 30 minutes per day"
          />
        <DistanceTextArea
          v-if="decorator.config.type == 'DISTANCE'"
          v-model="decorator.config.distance_m" 
          :mode="$helpers.UnitType.DISTANCE"
          :unit="event.unit"
          :label="`Distance (per day)`" 
          />        
        <DistanceTextArea
          v-if="decorator.config.type == 'CUSTOM'"
          v-model="decorator.config.custom" 
          :mode="$helpers.UnitType.NUMBER"
          :label="`Number of ${decorator.config.custom_label} (per day)`" 
          />        
      </div>
    </v-sheet>    

    <v-sheet v-if="decorator.type === 'MAP' " class="px-0 mb-4" :elevation="0" color="transparent">
      <v-switch 
        v-model="decorator.enabled"
        hide-details
        label="Show progress on a virtual map"
        @change="$emit('change', decorator)"
        />
      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        Optionally select a journey to apply to your leaderboard.
      </div>
      <div v-if="decorator.enabled" class="decorator-fields">
        <select-map-dialog v-model="decorator.config.map_id" ref="mapDialog" />
        <v-sheet v-if="!decorator.config.map_id" class="dotted mt-4 align-center clickable pa-2" @click="() => $refs.mapDialog.open()">
          <v-icon>fa-map</v-icon>
          Choose a Map
          <span style="color:red;">*</span>
        </v-sheet>
        <v-alert v-if="decorator.config.map_id" type="success" class="mt-4" dismissible @input="decorator.config.map_id=null">
          Journey <strong>{{mapTemplates.find(x => x.id == decorator.config.map_id).name }}</strong> selected. 
        </v-alert>
        
      </div>
    </v-sheet>    

    <v-sheet v-if="decorator.type === 'WELLBEING' " class="px-0 mb-4" :elevation="0" color="transparent">
      <v-switch 
        v-model="decorator.enabled"
        hide-details
        label="Add a set of wellbeing goals"
        @change="$emit('change', decorator)"
        >
      </v-switch>

      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        Select a collection of pre-defined wellbeing goals and use it for your event.
      </div>
      <div v-if="decorator.enabled" class="decorator-fields">

        <v-sheet v-if="!decorator.config.collection_id" class="dotted mt-4 align-center clickable pa-2" @click="() => $refs.wellbeingPackDialog.open()">
          <v-icon>fa-folders</v-icon>
          Choose a collection
          <span style="color:red;">*</span>
        </v-sheet>
        <select-wellbeing-pack-dialog v-model="decorator.config.pack_id" ref="wellbeingPackDialog" />
        <v-alert v-if="decorator.config.pack_id" type="success" class="mt-4" dismissible @input="decorator.config.pack_id=null">
          <strong>{{wellbeingPacks.find(x => x.id == decorator.config.pack_id).name }}</strong> pack selected. 
        </v-alert>        
      </div>
    </v-sheet>

    <v-sheet v-if="decorator.type === 'RACE_GOAL' " class="px-0 mb-4" :elevation="0" color="transparent">
      <v-switch 
        v-model="decorator.enabled"
        hide-details
        @change="$emit('change', decorator)"
        >
        <template v-slot:label>
          Set a goal for this leaderboard
        </template>
      </v-switch>

      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        Make this leaderboard either open-ended or set a specific goal.
      </div>
      <div v-if="decorator.enabled" class="decorator-fields">
        <v-row>
          <v-col class="grow">
            <DistanceTextArea
              v-model="decorator.config.value" 
              :unit="event.unit"
              :label="eventUtil.goalLabel()" 
              :mode="eventUtil.goalDistanceUnitType()"
              :multiplier="eventUtil.goalDistanceMultiplier()"
              hint="This goal is for the entire challenge"
              />
          </v-col>
          <v-col class="shrink pt-8">
            <v-btn text small @click="() => $refs.goalDialog.open()"><v-icon x-small class="me-1">fa-calculator</v-icon> Calculator</v-btn>
          </v-col>
        </v-row>
        <CalculateRaceGoalDialog v-model="decorator.config.value" :event="event" :race="leaderboard" ref="goalDialog" />


      </div>
    </v-sheet>    

    <v-sheet v-if="decorator.type === 'COLLECTIVE_GOAL' " class="px-0 mb-4" :elevation="0" color="transparent">
      <v-switch 
        v-model="decorator.enabled"
        hide-details
        @change="$emit('change', decorator)"
        >
        <template v-slot:label>
          Show collective progress
        </template>
      </v-switch>


      <div :class="{'decorator-fields': true, 'grey--text': !decorator.enabled}">
        Enable to show the collective progress of all participants combined.
      </div>
      <div v-if="decorator.enabled" class="decorator-fields">
        <v-row>
          <v-col class="grow">
            <DistanceTextArea
              v-model="decorator.config.value" 
              :unit="event.unit"
              :label="eventUtil.goalLabel()" 
              :mode="eventUtil.goalDistanceUnitType()"
              :multiplier="eventUtil.goalDistanceMultiplier()"
              hint="This goal is for all participants combined"
              />
          </v-col>
          <v-col class="shrink pt-8">
            <v-btn text small @click="() => $refs.collectiveGoalDialog.open()"><v-icon x-small class="me-1">fa-calculator</v-icon> Calculator</v-btn>
          </v-col>
        </v-row>
        <CalculateRaceGoalDialog v-model="decorator.config.value" :event="event" :race="leaderboard" collective-mode ref="collectiveGoalDialog" />


      </div>
    </v-sheet>    
  </div>
</template>


<script>
import assetsService from "@/services/assetsService";
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import SimpleTooltip from '@/components/SimpleTooltip.vue';
import ToggleButtonInput from "@/components/ToggleButtonInput";
import SelectMapDialog from '@/components/eventmanager/SelectMapDialog.vue';
import SelectWellbeingPackDialog from '@/components/eventmanager/SelectWellbeingPackDialog.vue';
import CalculateRaceGoalDialog from '@/components/eventmanager/CalculateRaceGoalDialog.vue';
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "DecoratorEditor",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    ToggleButtonInput,
    SelectMapDialog,
    SelectWellbeingPackDialog,
    CalculateRaceGoalDialog,
    SimpleTooltip,
  },
  props: {
    //value: Boolean,
    decorator: Object,
    leaderboard: Object,
    template: Object,
    event: Object,
    wellbeingPacks: Array,
    mapTemplates: Array,
    type: {
      type: String,
      default: 'EVENT',
    },
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.decorator.type == 'MAP') {
        console.log('/ MAP loaded:', this.decorator);
        var requestedMapTemplateId = this.eventUtil.getMetaStringValue('requested.map_id');
        if (requestedMapTemplateId) {
          // pre-select the map
          this.decorator.enabled = true;
          this.decorator.config.map_id = requestedMapTemplateId;
          this.$emit('change', this.decorator);
        }
      }
      if (this.decorator.type == 'WELLBEING') {
        var requestedWellbeingPackId = this.eventUtil.getMetaStringValue('requested.wb_pack_id');
        if (requestedWellbeingPackId) {
          // pre-select the pack
          this.decorator.enabled = true;
          this.decorator.config.pack_id = requestedWellbeingPackId;
          this.$emit('change', this.decorator);
        }
      }
    },
    
  },
  watch: {
    decorator(val) {
        //console.log('/ MAP watched:', val);
      
    },
  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event, this.leaderboard);
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>

<style lang="scss">
  .v-input--switch label { color: black !important; }

  .decorator-fields {
    margin-top: 8px;
      // margin-left: 24px;

  }  
  .v-sheet.dotted {
    border: 2px dotted #ccc; border-radius: 4px; background: white;
  }
</style>

