<template>
  <v-tooltip right transition="null">
    <template v-slot:activator="{ on, attrs }">
      <v-icon 
        :small="small" 
        :x-small="xSmall" 
        :color="color"
        :class="iconClass"
        v-bind="attrs"
        v-on="on"
        >
        fal {{icon}}
      </v-icon>
    </template>
    <span>{{text}}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "SimpleTooltip",
  props: {
    text: String,
    iconClass: String,
    icon: {
      type: String,
      default: 'fa-question-circle',
    },
    color: {
      type: String,
      default: 'blue',
    },
    small: {
      type: Boolean,
      default: true,
    },
    xSmall: Boolean,
  },
  components: {
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style scoped>
</style>