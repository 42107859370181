<template>
  <multi-step-dialog title="Select a Map" :step="step" ref="dialog" :can-confirm="!!templateId" @input="confirm">
    <v-stepper-items>
      <v-stepper-content :step="1" class="pa-0">
        <div v-if="!packs" class="text-center">
          <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
        </div>
        <v-container v-if="packs">
          <p>Select a map for details and to apply.</p>
          <v-row>
            <v-col v-for="(item, idx) in packs" :key="idx" cols="6" md="4" lg="3">
              <WellbeingPackCard :item="item" @click="selectTemplate(item)" />
            </v-col>
          </v-row>
        </v-container>

      </v-stepper-content>
      <v-stepper-content :step="2" class="pa-0">
        <v-container v-if="template">
          <v-row>
            <v-col>
              <h4>{{ template.name }}</h4>
              <p>{{ template.description }}</p>

              <p>
                <v-btn :to="{ name: 'challengeWellbeingPackView', params: {id: template.id }}" target="_blank" outlined class="">View Details</v-btn>
              </p>

            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </multi-step-dialog>
</template>

<script>
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import assetsService from "@/services/assetsService";
import WellbeingPackCard from "@/components/templates/WellbeingPackCard.vue";
import MultiStepDialog from "@/components/generic/MultiStepDialog.vue";

export default {
  name: "SelectWellbeingPackDialog",
  components: {
    MultiStepDialog,
    WellbeingPackCard,
  },
  props: {
    value: String,
  },
  data() {
    return {
      siteData: siteData,
      packs: null,
      templateId: null,
      step: 1,
    };
  },
  async mounted() {
  },
  methods: {

    async open() {
      this.step = 1;
      this.$refs.dialog.open();
      this.packs = this.packs || (await assetsService.getWellbeingPacks()).data.data;
    },

    async selectTemplate(item) {
      this.templateId = item.id;
      this.step++;
    },

    async confirm() {
      this.$emit('input', this.templateId);
    }

  },

  computed: {
    template() {
      if (!this.templateId) {
        return null;
      }
      return this.packs.find(x => x.id == this.templateId);
    }
  },

};
</script>
<style lang="scss">
.v-card.active { outline: solid 5px var(--v-accent-base);}
</style>

